import React, { useMemo } from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import View from 'web-react-ui/src/components/layout/View';
import Business from 'web-react-ui/src/types/Business.interface';
import AdminSocialSection from './social/AdminSocialSection';
import PostDetails from './social/PostDetails';

const AdminFeatures = ({ business }: { business: Business }) => {
  return (
    <View>
      <View.Section>
        <Switch>
          <Route path="/businesses/:businessId/social-ai/post/:postId" exact={true} component={PostDetails}/>
          <Route><AdminSocialSection business={business} /></Route>
        </Switch>
      </View.Section>
    </View>
  );
};

export default AdminFeatures;
